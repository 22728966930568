import { Repository } from "@react3l/react3l/core";
import { kebabCase, url } from "@react3l/react3l/helpers";
import { AxiosResponse } from "axios";
import { BASE_API_URL } from "config/consts";
import { httpConfig } from "config/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import nameof from "ts-nameof.macro";

import { API_DASHBOARD_PREFIX } from "config/api-consts";
import { Dashboard, DashboardFilter } from "models/Dashboard";
import { EndUser } from "models/EndUser";
import { Info } from "models/Info";
import { Ticket } from "models/Ticket";

export class DashboardRepository extends Repository {
  constructor() {
    super(httpConfig);
    this.baseURL = url(BASE_API_URL, API_DASHBOARD_PREFIX);
  }

  public filterListTime = (): Observable<Dashboard[]> => {
    return this.httpObservable
      .post<Dashboard[]>(kebabCase(nameof(this.filterListTime)))
      .pipe(map((response: AxiosResponse<Dashboard[]>) => response.data));
  };

  public listTicket = (): Observable<Ticket[]> => {
    return this.httpObservable
      .post<Ticket[]>(kebabCase(nameof(this.listTicket)))
      .pipe(map((response: AxiosResponse<Ticket[]>) => response.data));
  };

  public listEndUser = (): Observable<EndUser[]> => {
    return this.httpObservable
      .post<EndUser[]>(kebabCase(nameof(this.listEndUser)))
      .pipe(map((response: AxiosResponse<EndUser[]>) => response.data));
  };

  public overviewEndUser = (filter: DashboardFilter): Observable<Info> => {
    return this.httpObservable
      .post<Info>(kebabCase(nameof(this.overviewEndUser)), filter)
      .pipe(map((response: AxiosResponse<Info>) => response.data));
  };

  public overviewDormitory = (filter: DashboardFilter): Observable<Info> => {
    return this.httpObservable
      .post<Info>(kebabCase(nameof(this.overviewDormitory)), filter)
      .pipe(map((response: AxiosResponse<Info>) => response.data));
  };


  public overviewHomeController = (filter: DashboardFilter): Observable<Info> => {
    return this.httpObservable
      .post<Info>(kebabCase(nameof(this.overviewHomeController)), filter)
      .pipe(map((response: AxiosResponse<Info>) => response.data));
  };

  public overviewTicket = (filter: DashboardFilter): Observable<Info> => {
    return this.httpObservable
      .post<Info>(kebabCase(nameof(this.overviewTicket)), filter)
      .pipe(map((response: AxiosResponse<Info>) => response.data));
  };

  public overviewDevice = (filter: DashboardFilter): Observable<Info> => {
    return this.httpObservable
      .post<Info>(kebabCase(nameof(this.overviewDevice)), filter)
      .pipe(map((response: AxiosResponse<Info>) => response.data));
  };
}

export const dashboardRepository = new DashboardRepository();
