import { NumberFilter } from "@react3l/advanced-filters";
import { commonService } from "@react3l/react3l/services";
import { Card, Col } from "antd";
import { Dashboard, DashboardFilter } from "models/Dashboard";
import { Info } from "models/Info";
import React, { ReactNode, useEffect, useState } from "react";
import { Observable } from "rxjs";

interface OverviewInfoProps {
  filter: Partial<Dashboard>;
  title: string;
  getData: (filter: DashboardFilter) => Observable<any>;
  icon: string | ReactNode;
  color: string;
}

export default function OverviewInfo(props: OverviewInfoProps) {
  const { filter, title, getData, icon, color } = props;

  const [info, setInfo] = useState<Info>(null);

  const [subscription] = commonService.useSubscription();

  useEffect(() => {
      subscription.add(
        getData({
          time: new NumberFilter({ equal: filter.time }),
          type: new NumberFilter({equal: filter.type}),
          skip: 0,
          take: 1
        }).subscribe((data: Info) =>{
          setInfo(data);
        }
        )
      );
  }, [filter, getData, subscription]);

  return (
    <>
      <Col span={4}>
        <Card
          bordered={false}
          bodyStyle={{
            backgroundColor: "#fff",
            transition: "background-color 0.3s ease",
          }}
          className="dashboard-master__overview"
        >
          <div className="icon" style={{ backgroundColor: color }}>
            <i className={icon as string} style={{ color: "white" }}></i>
          </div>
          <div className="stat">
            <p className="m-0">{title}</p>
            <h2 className="m-0">{filter.type===1?info?.quantity: `${Math.round(info?.rate * 100)}%`}</h2>
          </div>
        </Card>
      </Col>
    </>
  );
}
