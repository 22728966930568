import { Card, Col, Row, Select } from "antd";
import { Dashboard } from "models/Dashboard";
import React, { useState } from "react";
import { dashboardRepository } from "repositories/dashboard-repository";
import "./DashboardMaster.scss";
import OverviewInfo from "./OverviewInfo";
import RecentEndUsers from "./RecentEndUsers";
import RecentTickets from "./RecentTickets";

const { Option } = Select;

export default function DashboardMaster() {
  const [filter, setFilter] = useState<Dashboard>({
    time: 1,
    type: 1
  });
  return (
    <div className="page page__master dashboard-master__container">
      <div className="page__header d-flex justify-content-between">
        <div className="header-logo">
          <div className="splatter"></div>
          <h4 className="header-title">Chào mừng đến</h4>
          <h3 className="header-title font-weight-bold">Rạng Đông Portal</h3>
        </div>
        <div className="d-flex">
          <div>
            <h6>Dữ liệu</h6>
            <Select
              defaultValue={1}
              style={{ width: 120, borderStyle:'solid', borderWidth:1, borderRadius: 4, marginRight: 8}}
              onChange={(val) => setFilter({...filter, time: val})}
              bordered={false}
            >
              <Option value={1}>Tháng</Option>
              <Option value={2}>Quý</Option>
              <Option value={3}>Năm</Option>
            </Select>
          </div>
          <div>
            <h6>Hiển thị</h6>
            <Select
              defaultValue={1}
              style={{ width: 150, borderStyle:'solid', borderWidth:1, borderRadius: 4}}
              onChange={(val) => setFilter({...filter, type: val})}
              bordered={false}
              >
              <Option value={1}>Thống kê</Option>
              <Option value={2}>Tăng trưởng</Option>
            </Select>
          </div>
        </div>
      </div>
      <div className="page__body">
        <Row justify={'space-between'}>
          <OverviewInfo
            filter={filter}
            title="Khách hàng"
            getData={dashboardRepository.overviewEndUser}
            icon="tio-account_circle"
            color="#2f80ed"
          />
          <OverviewInfo
            filter={filter}
            title="Nhà"
            getData={dashboardRepository.overviewDormitory}
            icon="tio-home_outlined"
            color="#9878"
          />
          <OverviewInfo
            filter={filter}
            title="Home Controller"
            getData={dashboardRepository.overviewHomeController}
            icon="tio-speaker"
            color="#f2994a"

          />
          <OverviewInfo
            filter={filter}
            title="Thiết bị"
            getData={dashboardRepository.overviewDevice}
            icon="tio-plug_outlined"
            color="#219653"
          />
          <OverviewInfo
            filter={filter}
            title="Vấn đề"
            getData={dashboardRepository.overviewTicket}
            icon="tio-ticket"
            color="#2f8098"
          />
           
        </Row>

        <Row
          gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
          className="mt-4 dashboard-master__recent"
        >
          <Col span={8}>
            <Card
              title={<strong>Khách hàng mới</strong>}
              bordered={false}
            >
              <RecentEndUsers getData={dashboardRepository.listEndUser} />
            </Card>
          </Col>
          <Col span={16}>
            <Card
              title={<strong>Khiếu nại mới</strong>}
              bordered={false}
            >
              <RecentTickets getData={dashboardRepository.listTicket} />
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
}
